<template>
  <button class="btn btn-primary" @click="setAutoSearch()">Автопоиск</button>
  <button v-if="sound_on" class="btn btn-light m-1" @click="sound_on=0">🔔</button>
  <button v-else class="btn btn-light m-1" @click="sound_on=1">🔇</button>
  <div class="row">
    <div class="col-4">
      <label for="" class="col-sm-12 col-form-label">Минимальный уровень подсветки ячейки</label>
    </div>
    <div class="col-4">
      <input class="form-control" type="number" v-model="min_signal_value">
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="" class="col-sm-12 col-form-label">Минимальный уровень звукового сигнала</label>
    </div>
    <div class="col-4">
      <input class="form-control" type="number" v-model="min_sound_value">
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="" class="col-sm-12 col-form-label">Максимальный уровень прогрессбара</label>
    </div>
    <div class="col-4">
      <input class="form-control" type="number" v-model="max_signal_progressbar">
    </div>
  </div>

  <hr>
  <h4>Каналы</h4>
  <table class="mt-2 table table-bordered" v-if="data_signals.length > 0">
    <tbody>
    <tr v-for="t in [1,7,13,19,25,31,37,43]" :key="t">
      <td v-for="channel in 6"
          :key="channel"
          @click="selectChannel(channel+t-1)"
          :id="channel+t-1+'channel'"
          :class="{'bg-have-signal': min_signal_value<=data_signals[channel + t] }">
        #{{ channel + t - 1 }} | {{ data_signals[channel + t] }}
        <ProgressBar :value="getPercentSignal(data_signals[channel + t])"
                     :ptOptions="{ mergeProps: true }"
                     :pt:value:class="getSignalBackgroundColor(data_signals[channel + t])">
        </ProgressBar>
      </td>
    </tr>

    </tbody>
  </table>
  <div v-else>Нет данных, ждем подключения...</div>
  <hr>
  <h4>Текущий канал <span v-if="work_type">(Авто)</span><span v-else>(Ручной режим)</span></h4>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <ul>
            <li>Канал {{ current_channel }}</li>
            <li>Частота 555Mhz</li>
            <li>RSSI: {{ data_signals[51] }}</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <button class="btn btn-danger">Игнорировать</button>
        </div>
        <div class="col-9">
          <div class="row mb-3">

            <div class="col-sm-3">
              <input type="number" class="form-control" placeholder="5">
            </div>
            <label for="minutes" class="col-sm-4 col-form-label">Минут</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <h4>Debug Info</h4>
  <div class="card">
    <div class="card-body">
      {{ now }}: {{ data_signals }}
    </div>
  </div>
  <audio id="audio" src="/static/beep.mp3"></audio>

  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <img src="" class="rounded me-2" alt="...">
        <strong class="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"

export default {
  name: 'SettingsCamera',
  props: {
    msg: String,
  },
  setup() {


  },
  created() {
    this.ConnectServer()
  },
  data() {
    return {
      now: '', // Текущее время
      data_signals: [],
      current_channel: '-',
      connect_status: 'Подключение...',
      selected_channel: 0,
      min_signal_value: 440,
      auto_search: 0,
      min_sound_value: 440,
      max_signal_progressbar: 440,
      sound_on: 0,
      work_type: 1 // Автоматический, 0 - ручной
    }
  },
  watch: {},
  methods: {
    ConnectServer() {
      console.log("connect..")
      var ref = this
      let ws_url = "ws://212.192.14.181:8001/"
      var connection = new WebSocket(ws_url)
      connection.onmessage = function (event) {
        ref.now = Date(Date.now())
        ref.data_signals = JSON.parse(event.data);
        ref.current_channel = ref.data_signals[50]
        let active_channel_td = $('#' + ref.current_channel + 'channel')
        let check_active = active_channel_td.hasClass('active-channel')
        if (check_active === false) {
          $('td').removeClass("active-channel");
          active_channel_td.addClass('active-channel')
        }
        for (let signal in ref.data_signals.slice(0, -2)) {
          if (ref.data_signals[signal] > ref.min_sound_value && ref.sound_on === 1) {
            console.log("Play sound")
            let audio = document.getElementById("audio");
            audio.play();
            break
          }
        }
        if (ref.selected_channel !== 0) {
          connection.send(`Set channel,${ref.selected_channel}`)
          ref.selected_channel = 0
          ref.auto_search = 0
          ref.work_type = 0
        } else if (ref.auto_search === 1) {
          connection.send(`Set auto`)
          ref.auto_search = 0
          ref.work_type = 1
        } else {
          connection.send("GET!")
        }

      }
      connection.onerror = function (e) {
        console.log("Some error in socket", e.reason)
        ref.connect_status = 'Ошибка в сокете'
      }
      connection.onclose = function (e) {
        ref.data_signals = []
        console.log("Socket is closed. Try to reconnect...", e.reason)
        ref.connect_status = 'Сокет закрыт, пробуем переподключиться...'
        setTimeout(function () {
          ref.ConnectServer();
        }, 5000);
      }
      connection.onopen = function () {
        console.log("Successfully connected to the echo websocket server...")
        connection.send("GET!")
      }
    },

    setAutoSearch() {
      this.auto_search = 1
    },
    selectChannel(channel) {
      this.selected_channel = channel
    },
    getPercentSignal(signal) {
      return Math.round((100 * signal) / this.max_signal_progressbar)
    },
    getSignalBackgroundColor(signal) {
      let percent = Math.round((100 * signal) / this.max_signal_progressbar)
      if (80 <= percent && percent <= 90) {
        return "bg-warning"
      } else if (percent < 80) {
        return "bg-danger"
      }
    },
  }
}


</script>
<script setup>
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

td {
  cursor: pointer;
}

a {
  color: #42b983;
}

.bg-have-signal {
  --bs-bg-opacity: 1;
  background-color: #dcfaed !important;
}

.active-channel {
  border-width: 2px !important;
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}
</style>
