<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <div class="col-6">
        <div class="card vh-100">
          <div class="card-header">
            <div class="card-title">Камера</div>
          </div>
          <div class="card-body">
          <iframe class="w-100 h-100" src="http://10.25.11.195/axis-cgi/mjpg/video.cgi?camera=1"></iframe>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-header">
            <div class="card-title">Управление</div>
          </div>
          <div class="card-body">
            <SettingsCamera/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SettingsCamera from "@/components/Settings";

export default {
  name: 'App',
  components: {
    SettingsCamera,
  },
}

</script>

<style>
#app {
  margin-top: 20px
}
</style>
