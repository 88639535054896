import {createApp} from 'vue'
import PrimeVue from 'primevue/config';
import App from './App.vue'
import Aura from '@primevue/themes/aura';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import ProgressBar from "primevue/progressbar";


const app = createApp(App);
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
app.component("ProgressBar", ProgressBar);
app.mount('#app')